<template>
  <v-card
    class="messagerie-list--item ma-1 mt-5 elevation-2"
    :class="{
      clicked: selected,
      'urgency-fiche': fiche.fiche.impact.value.id === 4,
      new_fiche: fiche.fiche.new_fiche && !selected,
      archived: (fiche.fiche.archived || fiche.fiche.old_fiche) && !selected,
      'newMessageReceived--light':
        userNotSeen(fiche) > 0 && !$vuetify.theme.dark,
      'newMessageReceived--dark':
        userNotSeen(fiche) > 0 && $vuetify.theme.dark,
      'internal-message': (
        this.fiche.fiche.last_message_interne &&
        this.fiche.fiche.last_message &&
        this.fiche.fiche.last_message_interne.time > this.fiche.fiche.last_message.time &&
        !this.fiche.fiche.last_message_interne.isSeen && 
        !this.selected &&
        getMessagerieData().rightsUser.hotline
      ) ? true : false,
    }"
  >
    <v-toolbar dense color="transparent" class="elevation-0">
      <v-chip
          v-if="fiche.fiche.impact.value.id === 4"
          class="etiquette--fiche mr-4 pa-2 mt-1 elevation-2"
          color="error"
      >
        <v-icon color="white" small>
          mdi-alert
        </v-icon>
      </v-chip>
      <v-chip
        x-small
        color="primary"
        label
        dark
        class="font-weight-bold elevation-1 etiquette--fiche mr-2"
        @click.stop="
          copyToClipboard(makeUrl(fiche), $nSuccess($t('actions.copied')))
        "
        >{{ fiche.fiche.ticket }}
      </v-chip>

      <v-chip
        x-small
        color="primary"
        label
        dark
        class="font-weight-bold elevation-1 etiquette--fiche mr-2"
      >
        {{ getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules).module.name }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            v-if="fiche.fiche.phone_be_contacted"
            color="success"
            x-small
            label
            dark
            class="font-weight-bold elevation-1 etiquette--fiche mr-2"
            @click.stop="
              copyToClipboard(
                fiche.fiche.phone,
                $nSuccess($t('actions.copied'))
              )
            "
            ><v-icon small>mdi-phone-incoming-outline</v-icon>
          </v-chip>
        </template>
        <span>{{ fiche.fiche.phone }}</span>
      </v-tooltip>

      <v-chip
        x-small
        :color="getColorEtat(fiche.fiche.etat.value.id)"
        label
        dark
        class="font-weight-bold elevation-1 etiquette--fiche"
        >{{ $t("messagerie.parametrages." + fiche.fiche.etat.text) }}
      </v-chip>
      <div v-if="user.superAdministrator" cols="" class="text-small px-1">
        <v-icon
          v-if="fiche.fiche.suivi"
          small
          color="#F0F"
          title="Fiche suivie"
        >
          mdi-bookmark-check
        </v-icon>
        <v-icon
          v-else-if="!fiche.fiche.suivi"
          small
          color="#ccc"
          title="Fiche non suivie"
        >
        mdi-bookmark-remove
        </v-icon>
         

      </div>
      <v-btn v-if="user.superAdministrator" x-small icon @click.stop="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
      <div class="d-flex flex-column align-center mt-11">

        <menu-fiche
          :mini="true"
          :fiche="fiche"
          :etats="getMessagerieData().etats"
          :impacts="getMessagerieData().impacts"
          :ordre_priorites="getMessagerieData().ordre_priorites"
          :type_demandes="getMessagerieData().type_demandes"
          :rightsUser="getMessagerieData().rightsUser"
          :tagsPlaneteOnline="getMessagerieData().tagsPlaneteOnline"
          :users="getMessagerieData().users"
          :user="user"
        />
        <!-- icon ouvrir dans un nouvel onglet -->
        <a :href="makeUrl(fiche)" @click.stop target="_blank" rel="noopener noreferrer">
          <v-icon color="primary" size="15" title="Ouvrir dans un nouvel onglet">
            mdi-open-in-new
          </v-icon>
        </a>
        <!-- icon appel client -->
        <v-icon v-if="user.superAdministrator && fiche.fiche.appel_client && fiche.fiche.appel_client.length > 0"
        class="mt-1"
        small
        color="green"
        :title="fiche.fiche.appel_client[fiche.fiche.appel_client.length - 1].user"
        >
          mdi-phone
        </v-icon>
        <v-icon v-else-if="user.superAdministrator && fiche.fiche.appel_client && fiche.fiche.appel_client.length == 0"
          class="mt-1"
          small
          color="#ccc"
          title="Pas d'appel client"
        >
          mdi-phone-hangup
        </v-icon>
      </div>
    </v-toolbar>

    <v-card-text class="d-flex flex-row align-center mt-n12">
      <!-- Partie gauche de l'élément de liste : Avatar de l'utilisateur -->
      <div class="messagerie-list--item--header ml-n2">
        <!-- Badge affichant le nombre de messages non lus -->
        <v-badge
          color="error"
          :value="userNotSeen(fiche)"
          :content="userNotSeen(fiche)"
          overlap
          offset-y="20"
          offset-x="20"
        >
        <!-- Avatar de l'utilisateur avec une photo de profil -->
          <v-avatar size="64" class="mb-2">
            <img
              class="ma-n4"
              style="width: 80px;height: auto;"
              :src="
                require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')
              "
              alt="Profile photo"
            />
          </v-avatar>
        </v-badge>
        <!-- Affichage de la date du dernier message si ce n'est pas d'aujourd'hui -->
        <p
          class="grey--text lighten-3 text-small"
          v-if="!checkMessageToday(lastMessage(fiche.fiche).time)"
        >
          {{ dateMessageChat(lastMessage(fiche.fiche).time, "LL")  }}
        </p>
        <!-- Affichage de l'heure du dernier message -->
        <p class="grey--text lighten-3 p-0 text-smaller">
          {{ dateMessageChat(lastMessage(fiche.fiche).time, "HH[h]mm") }}
        </p>
      </div>
      <!-- Partie droite de l'élément de liste : Contenu du message -->
      <div class="messagerie-list--item--content ml-4">
        <!-- Nom ou objet de la conversation -->
        <span class="messagerie-list--item--content--name" :class="{'urgency-title': fiche.fiche.impact.value.id === 4}">
          {{ fiche.fiche.objet }}
        </span>
        <!-- Nom ou objet de la conversation -->
        <p class="fiche--objet font-weight-bold mr-2">
          {{ usernameUser(fiche.fiche.userCreate) }}
          <!--          {{ usernameUser(lastMessage(fiche.fiche).user) }}-->
        </p>
        <!-- Affichage d'une puce colorée avec le nom du client -->
        <div class="d-flex align-center justify-start">
          <v-chip x-small color="cyan" label dark class="elevation-1">
            {{ fiche.fiche.client }}
          </v-chip>
          <!-- Affichage de l'URL associée à la conversation -->
          <span
            class="messagerie-list--item--content--url cyan--text text-smaller ml-2"
            >{{ fiche.fiche.url.value.name }}</span
          >
        </div>
         <!-- Affichage du dernier message de la conversation -->
        <span class="fiche--lastmessage mt-2">
          <!-- Affichage d'une icône en fonction du type de message (peut être une information, une pièce jointe, etc.) -->
          <v-icon
            v-if="lastMessage(fiche.fiche).type == 1"
            class="teal--text mr-2 align-self-start"
            >mdi-information
          </v-icon>
           <!-- Affichage du nom de l'utilisateur qui a envoyé le dernier message -->
          <span class="text-small"
            ><v-icon v-if="lastMessage(fiche.fiche).files != null"
              >mdi-paperclip</v-icon
            >{{ usernameUser(lastMessage(fiche.fiche).user) }} :
          </span>
            <!-- Affichage du contenu du dernier message avec une mise en forme en fonction du statut de lecture -->
            <span
            :class="[
              userNotSeen(fiche) > 0 && !$vuetify.theme.dark
                ? ['font-weight-bold', 'grey--text text--darken-4']
                : [],
              userNotSeen(fiche) > 0 && $vuetify.theme.dark
                ? ['font-weight-bold', 'white--text']
                : [],
              this.fiche.fiche.last_message_interne &&
              this.fiche.fiche.last_message &&
              this.fiche.fiche.last_message_interne.time > this.fiche.fiche.last_message.time &&
              !this.fiche.fiche.last_message_interne.isSeen && 
              !this.selected &&
              getMessagerieData().rightsUser.hotline
                ? ['font-weight-bold']
                : [],
              
            ]"
            v-html="minifyMessage(lastMessage(fiche.fiche).content)"
          ></span>
        </span>
      </div>
      <div class="ml-4 pa-0 align-self-end " v-if=" fiche.fiche.date_previsionnelle && isGtaOrCompteur()">
        <h5 class="date-prev-text-font">{{ $t('messagerie.date_previsionnelle') }}</h5>
        <p >{{ formatedDate}}</p>
      </div>

    </v-card-text>
    <!--    <v-card-actions>-->
    <!--      -->
    <!--    </v-card-actions>-->
    <v-expand-transition>
      <div v-show="show">
        <v-divider class="mr-2"></v-divider>

        <v-card-text>
          <v-list-item dense class="pa-0" v-if="fiche.fiche.phone">
            <v-list-item-title class="d-flex flex-row align-center justify-start">
              <v-icon :color="this.fiche.fiche.phone_be_contacted ? 'success' : 'error'"
                  :title="this.fiche.fiche.phone_be_contacted ? 'À rappeler' : 'Ne pas rappeler'"
              >mdi-phone-incoming-outline</v-icon>
              <img
                v-if="fiche.fiche.phoneCountry"
                class="ml-8 mr-2"
                :src="'https://flagcdn.com/20x15/' + fiche.fiche.phoneCountry.toLowerCase() + '.png'"
                width="16"
                height="11"
              />
              <span>{{ fiche.fiche.phone }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-chip
            x-small
            :color="getPrioriteColor"
            label
            dark
            class="elevation-1 mr-4 chip-truncate"
          >
            {{
              $t("messagerie.parametrages." + fiche.fiche.ordre_priorite.text)
            }}
          </v-chip>
          <v-chip
            x-small
            :color="getImpactColor"
            label
            dark
            class="elevation-1 mr-4 chip-truncate"
          >
            {{ $t("messagerie.parametrages." + fiche.fiche.impact.text) }}
          </v-chip>
          <v-chip
            x-small
            :color="ColorsUtils.getTypeColor(fiche.fiche.type_demande.value.id)"
            label
            dark
            class="elevation-1 mr-4 chip-truncate"
          >
            {{ $t("messagerie.parametrages." + fiche.fiche.type_demande.text) }}
          </v-chip>
          <v-chip
            x-small
            color="primary"
            label
            dark
            class="elevation-1 chip-truncate"
            v-if="fiche.fiche.sous_module"
          >
            {{
              getModuleName(
                fiche.fiche.module,
                fiche.fiche.sous_module,
                modules
              ).sous_module.name
            }}
          </v-chip>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import HotlineBus from "@/Components/Views/Hotline/HotlineBus";
import moment from "moment";
// Import du mapGetters pour faciliter les accès aux différents store
import ColorsUtils from "@/Utils/MessagerieColors";
import { mapGetters, mapState } from "vuex";


export default {
  name: "TicketCard",
  props: {
    fiche: Object,
    selected: Boolean,
    user: Object,
  },
  components: {
    MenuFiche: () => import("@/Components/Views/Hotline/Ticket/TicketMenu"),
  },
  computed: {
    ...mapGetters(["getMessagerieData"]),
    ...mapState(["modules"]),
    formatedDate() {
      return moment(this.fiche.fiche.date_previsionnelle).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      ColorsUtils,
      tmpNotSeen: [],
      show: false,
    };
  },
  methods: {
    // method pour verifier si cest une fiche du module GTA ou une fiche avec le tag Compteur
    isGtaOrCompteur() {
      if (this.fiche.fiche.module.name == 'GTA' || this.fiche.fiche.tags.find((tag) => tag.tag.name == 'Compteur')) {
        return true;
      } else {
        return false;
      }
    },
    makeUrl(fiche = null) {
      if (fiche) {
        return "https://" + this.getDns() + "/planete-online/app/hotline?ticket=" + fiche._id
      }
    },
    async getSubModuleName(subModule, module) {
      if (await this.isMasterBase()) {
        return typeof subModule != "undefined" ? subModule.name : "";
      } else {
        if (this.modules) {
          let moduleFound = this.modules.find((mod) => mod.id == module.id);

          if (moduleFound && moduleFound.subModules) {
            let subModuleFind = moduleFound.subModules.find(
              (subMod) => subMod.id == subModule.id
            );
            return typeof subModuleFind != "undefined"
              ? subModuleFind.name
              : "";
          } else {
            return subModule.name;
          }
        } else {
          return subModule.name;
        }
      }
    },
    /**
     * Permet de savoir combien de message l'user n'a pas vu
     * @var fiche la fiche
     */
    userNotSeen(fiche) {
      if (typeof fiche.fiche.usersSeen == "undefined") {
        return fiche.fiche.number_messages;
      }
      if (fiche.fiche.usersSeen.length == 0) {
        return fiche.fiche.number_messages;
      } else {
        let userSeen = fiche.fiche.usersSeen.find(
          (u) => u.idUser == this.user.id
        );
        if (typeof userSeen != "undefined") {
          return userSeen.nb;
        } else {
          return fiche.fiche.number_messages || 0;
        }
      }
    },
    /**
     * Permet de savoir quel dernier message afficher dans la liste de gauche
     * @var fiche la fiche
     */
    lastMessage(fiche) {
      if (
        typeof fiche.last_message_interne != "undefined" &&
        this.getMessagerieData().rightsUser &&
        Object.keys(this.getMessagerieData().rightsUser).length > 0
      ) {
        typeof fiche.last_message == 'undefined'
          ? (fiche.last_message = fiche.messages)
          : fiche.last_message;
        return fiche.last_message_interne.time > fiche.last_message.time
          ? fiche.last_message_interne
          : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    checkMessageToday(time) {
      let ymdNow = moment().format("MM-DD-YYYY");
      let ymdMsg = moment(time * 1000).format("MM-DD-YYYY");
      if (ymdNow == ymdMsg) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Converti un timestamp en date lisible
     * @var time le timestamp
     */
    dateMessageChat(time, format = null) {
      return moment(time * 1000).format(format);
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + " " + user.lastname.toUpperCase();
    },
    /**
     * Permet d'avoir le nombre d'autres utilisateurs dans la fiche
     * @var fiche La fiche
     */
    getNbUserFiches(fiche) {
      return fiche.fiche.users
        .filter((u) => {
          if (u.id != this.user.id) {
            return u;
          }
          if (u.id != fiche.fiche.userCreate.id) {
            return u;
          }
        })
        .sort(this.compareUsers).length;
    },
    minifyMessage(message) {
      return message.replace(/(<([^>]+)>)/gi, "");
    },
  },
  created() {
    HotlineBus.$on("toggle-expand-message", (toggleState) => {
      this.show = toggleState;
    });
  },
  mounted() {
    // afficher date prévisionnelle au format français
    // if (this.fiche.fiche.date_previsionnelle) {
    //   this.fiche.fiche.date_previsionnelle = moment(this.fiche.fiche.date_previsionnelle).format("DD/MM/YYYY");
    // }
  },
};
</script>

<style lang="scss" scoped>
.messagerie-list--item {
  &:hover {
    cursor: pointer;
  }

  &.new_fiche {
    background-color: var(--v-primary-lighten5);
  }

  &.newMessageReceived--light {
    background-color: var(--v-whiteDarkable-lighten1);
    border: 5px solid #cccccc !important;
    box-shadow: 0 0 15px 10px #ccc inset !important;
  }

  &.newMessageReceived--dark {
    background-color: #fff;
    // background-color: var(--v-whiteDarkable-lighten2);
    border: 5px solid #fff !important;
    box-shadow: 0 0 15px 10px #ccc inset !important;
  }

  &.internal-message {
    font-weight: bold;
    color: #000;
    border: #009688 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
  }
  &.internal-message-dark {
    font-weight: bold;
    color: #fff;
    border: #009688 1px solid;
    box-shadow: 0 0 10px 3px #009688 inset;
  }
}

.chip-truncate {
  max-width: calc(100% - 16px);

  span {
    line-height: 17px;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
}
.urgency-title {
  color: var(--v-error-base) !important;
  font-weight: 900 !important;
  text-transform: uppercase;
  text-decoration: underline;
}

.date-prev-text-font {
  font-size: 12px;
  font-weight: 900;
  color: var(--v-primary-base);
}

</style>
